/**
 * 機能種別の配列
 */
export const StableFeatureTypes = Object.freeze([
    "none",
    "File",
    "EventLog",
    "AggregateEventlog",
    "graph",
    "map",
    "karte",
    "warningList",
    "ecclink",
    "operatingRatio",
    "AgvPosition",
    "camera",
    "liveStreaming",
] as const);

/**
 * 実験的な機能種別の配列
 */
export const ExperimentalFeatureTypes = Object.freeze([
    "inquiryChart",
    "userReport",
    "nodeHistory",
] as const);

/**
 * 全ての機能種別の配列
 */
export const AllFeatureTypes = Object.freeze([
    ...StableFeatureTypes,
    ...ExperimentalFeatureTypes,
] as const);

/**
 * 機能種別の型
 */
export type FeatureType = typeof AllFeatureTypes[number];

/**
 * 各 {@link FeatureType} の表示ラベル
 */
export const FeatureTypeLabels = Object.freeze<Record<FeatureType, string>>({
    none: "トップビュー",
    File: "ファイル",
    EventLog: "イベントログ",
    AggregateEventlog: "集約イベントログ",
    graph: "傾向",
    map: "マップ",
    karte: "作業メモ",
    warningList: "異常一覧",
    ecclink: "電力系統状態",
    operatingRatio: "稼働実績",
    AgvPosition: "AGV位置表示",
    camera: "カメラ",
    inquiryChart: "障害調査",
    userReport: "不具合報告",
    liveStreaming: "LIVE",
    nodeHistory: "製品カルテ",
});

export namespace isFeatureType {
    /** {@link isFeatureType} 関数のオプション */
    export interface Options {
        /** 実験的機能を許可するフラグ */
        allowExperimental?: boolean | undefined;
    }
}

/**
 * 値が {@link FeatureType} のひとつかどうか判定します。
 *
 * @param x 判定する値
 * @returns 値が {@link FeatureType} のひとつであれば真、それ以外は偽。
 */
export function isFeatureType(
    x: unknown,
    options?: isFeatureType.Options | undefined,
): x is FeatureType {
    const { allowExperimental = false } = options ?? {};

    return (
        StableFeatureTypeSet.has(x) ||
        (allowExperimental ? ExperimentalFeatureTypeSet.has(x) : false)
    );
}

const StableFeatureTypeSet: ReadonlySet<unknown> = new Set(StableFeatureTypes);
const ExperimentalFeatureTypeSet: ReadonlySet<unknown> = new Set(
    ExperimentalFeatureTypes,
);
